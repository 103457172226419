<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap composing-image-block">
      <h2>
        {{ $t('creativity-15-17.title') }}
      </h2>
      <h3>
        {{ $t('creativity-15-17.methodology') }}
      </h3>
      <div class="collaboration-desc" v-html="$t('creativity-15-17.instruction')">
      </div>
      <button class="btn btn-primary" @click="addBlock" :disabled="isButtonDisabled"> + </button>
      <div v-for="(block, blockIndex) in blocks" :key="blockIndex" class="d-flex flex-md-row flex-column">
        <div class="container-image" :ref="'figureContainer' + blockIndex">
          <img
              v-for="(figure, index) in block.figures"
              :key="index"
              :src="figure.src"
              :alt="figure.alt"
              :style="figure.style"
              class="figure draggable"
              @wheel="rotateFigure($event, index, blockIndex)"
              @click="selectFigure(index, blockIndex)"
          />
        </div>
        <div class="container-image-settings">
          <el-input :placeholder="$t('creativity-15-17.namePicture')" v-model="block.nameImage"></el-input>
          <div class="settings-panel" v-if="block.selectedFigure !== null">
            <h3>{{ $t('figureSettings') }}</h3>
            <label>
              {{ $t('angle') }}:
              <input
                  type="range"
                  min="0"
                  max="360"
                  v-model.number="block.figures[block.selectedFigure].style.deg"
                  @input="updateFigureStyle(blockIndex, 'transform', 'rotate(' + $event.target.value + 'deg)')"
              />
              {{ block.figures[block.selectedFigure].style.deg }}
            </label>
            <button class="close-modal-btn" @click="deselectFigure(blockIndex)">✖</button>
          </div>
        </div>
      </div>
<!--      <button @click="saveAsImage">Сохранить</button>-->

      <div id="preview-container" style="margin-top: 20px;"></div>

      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{ modalTitle }}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendCreativitySubtest1Results" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';
import html2canvas from "html2canvas";

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      figures: [
        {
          src: "/images/composing/figure1_no_bg.png",
          alt: "figure1",
          style: {top: "10px", left: "10px", transform: "rotate(0deg)", deg: 0}
        },
        {
          src: "/images/composing/figure2_no_bg.png",
          alt: "figure2",
          style: {top: "100px", left: "100px", transform: "rotate(0deg)", deg: 0}
        },
        {
          src: "/images/composing/figure3_no_bg.png",
          alt: "figure3",
          style: {top: "200px", left: "200px", transform: "rotate(0deg)", deg: 0}
        }
      ],
      currentFigure: null,
      selectedFigure: null,
      offsetX: 0,
      offsetY: 0,
      blocks: [
        {
          figures: [
            {
              src: "/images/composing/figure1_no_bg.png",
              alt: "figure1",
              style: {top: "10px", left: "10px", transform: "rotate(0deg)", deg: 0}
            },
            {
              src: "/images/composing/figure2_no_bg.png",
              alt: "figure2",
              style: {top: "100px", left: "100px", transform: "rotate(0deg)", deg: 0}
            },
            {
              src: "/images/composing/figure3_no_bg.png",
              alt: "figure3",
              style: {top: "200px", left: "200px", transform: "rotate(0deg)", deg: 0}
            }
          ],
          currentFigure: null,
          selectedFigure: null,
          offsetX: 0,
          offsetY: 0,
          nameImage: '',
          isModified: false
        }
      ],
      isButtonDisabled: false,
      base64Image: '',
      interpretationResult: {
        high: 'Высокий уровень креативности («признаки одаренности»). У тебя отличное воображение, ты способен думать быстро, мыслить творчески, высказывать разнообразные идеи. Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу.',
        middle: 'Средний уровень креативности. У тебя хорошее воображение, ты мыслишь достаточно быстро и творчески,  Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу. ',
        low: 'Ниже среднего уровень креативности. У тебя хорошее воображение, но ты мог бы думать быстрее, оригинальнее и самостоятельно, придумывать собственные истории. Рекомендуется посещать психологические занятия для развития своего потенциала.',
      }
    };
  },
  methods: {
    addBlock() {
      this.blocks.push({
        figures: [
          {
            src: "/images/composing/figure1_no_bg.png",
            alt: "figure1",
            style: {top: "10px", left: "10px", transform: "rotate(0deg)", deg: 0}
          },
          {
            src: "/images/composing/figure2_no_bg.png",
            alt: "figure2",
            style: {top: "100px", left: "100px", transform: "rotate(0deg)", deg: 0}
          },
          {
            src: "/images/composing/figure3_no_bg.png",
            alt: "figure3",
            style: {top: "200px", left: "200px", transform: "rotate(0deg)", deg: 0}
          }
        ],
        currentFigure: null,
        selectedFigure: null,
        offsetX: 0,
        offsetY: 0,
        nameImage: '',
        isModified: false
      });

      this.$nextTick(() => {
        document.querySelectorAll('.draggable').DraggableJS();
      });

      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 10000);
    },
    deselectFigure(blockIndex) {
      this.blocks[blockIndex].selectedFigure = null;
    },
    selectFigure(index, blockIndex) {
      this.blocks[blockIndex].selectedFigure = index;
    },
    updateFigureStyle(blockIndex, property, value) {
      this.$set(this.blocks[blockIndex].figures[this.blocks[blockIndex].selectedFigure].style, property, value);
    },
    startDrag(event, index, blockIndex) {
      const block = this.blocks[blockIndex];
      block.currentFigure = index;
      const figure = event.target;

      const isTouchEvent = event.type === "touchstart";
      const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
      const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY;

      block.offsetX = clientX - figure.offsetLeft;
      block.offsetY = clientY - figure.offsetTop;

      if (isTouchEvent) {
        document.addEventListener("touchmove", this.drag, { passive: false });
        document.addEventListener("touchend", this.stopDrag);
      } else {
        document.addEventListener("mousemove", this.drag);
        document.addEventListener("mouseup", this.stopDrag);
      }

      event.preventDefault();
    },

    drag(event) {
      const blockIndex = this.blocks.findIndex(block => block.currentFigure !== null);
      if (blockIndex === -1) return;

      const block = this.blocks[blockIndex];
      const figure = block.figures[block.currentFigure];

      const isTouchEvent = event.type === "touchmove";
      const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
      const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY;

      const newLeft = Math.max(1, Math.min(clientX - block.offsetX, 310));
      const newTop = Math.max(1, Math.min(clientY - block.offsetY, 310));

      figure.style.left = `${newLeft}px`;
      figure.style.top = `${newTop}px`;

      block.isModified = true;
    },

    stopDrag() {
      const blockIndex = this.blocks.findIndex(block => block.currentFigure !== null);
      if (blockIndex === -1) return;

      const block = this.blocks[blockIndex];
      block.currentFigure = null;

      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchmove", this.drag);
      document.removeEventListener("touchend", this.stopDrag);
    },
    rotateFigure(event, index, blockIndex) {
      event.preventDefault();
      const figure = this.blocks[blockIndex].figures[index];

      if (!figure.style.transform) {
        figure.style.transform = 'rotate(0deg)';
      }

      const currentRotation = this.getRotation(figure.style.transform);
      const newRotation = currentRotation + (event.deltaY > 0 ? 15 : -15);
      figure.style.transform = `rotate(${newRotation}deg)`;
    },
    getRotation(transform) {
      const match = transform.match(/rotate\(([-\d.]+)deg\)/);
      return match ? parseFloat(match[1]) : 0;
    },
    async saveAsImage() {
      // временный контейнер
      const tempContainer = document.createElement("div");
      tempContainer.style.position = "absolute";
      tempContainer.style.left = "-9999px";
      tempContainer.style.top = "0";

      this.blocks.forEach((_, blockIndex) => {
        const container = this.$refs['figureContainer' + blockIndex][0].cloneNode(true);
        tempContainer.appendChild(container);
      });

      document.body.appendChild(tempContainer);
      const canvas = await html2canvas(tempContainer);
      const dataURL = canvas.toDataURL("image/png");
      this.base64Image = dataURL.replace(/^data:image\/png;base64,/, "");

      document.body.removeChild(tempContainer);

      // const imageElement = document.createElement("img");
      // imageElement.src = dataURL;
      // imageElement.alt = "Сохраненное изображение";
      // imageElement.style.maxWidth = "100%";
      //
      // const previewContainer = document.getElementById("preview-container");
      // previewContainer.innerHTML = "";
      // previewContainer.appendChild(imageElement);
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendCreativitySubtest1Results() {
      await this.saveAsImage()
      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      const quizToken = localStorage.getItem('quiz-token')
      const validBlocksLength = this.blocks.filter(block => block.nameImage && block.nameImage.length >= 3).length;
      const blocksToSend = this.blocks.map(obj => {
        const {currentFigure, selectedFigure, offsetX, offsetY, ...rest} = obj;
        return rest;
      });

      const creativitySubtest1Data = {
        score: validBlocksLength,
        addInfo: {blocks: blocksToSend},
        userImage: this.base64Image,
        interpret: this.interpretScore(validBlocksLength)
      }

      params.answer_id = {...params.answer_id, creativitySubtest1Data}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'creativity-subtest-2')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      setTimeout(() => {
        this.$router.push({ path: '/creativity-subtest-2' })
      }, 1500)

    },
    interpretScore(score) {
      if (score >= 25) {
        return this.interpretationResult.high;
      } else if (score >= 10) {
        return this.interpretationResult.middle;
      } else {
        return this.interpretationResult.low;
      }
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerCreativitySubtest1', savedTimer);

        if (--timer < 0) {
          this.sendCreativitySubtest1Results()
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerCreativitySubtest1')) {
      this.startTimer(localStorage.getItem('timerCreativitySubtest1'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;

    this.$nextTick(() => {
        document.querySelectorAll('.draggable').DraggableJS();
    });
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}

.container-image {
  width: 400px;
  height: 400px;
  border: 2px dashed #ccc;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.figure {
  position: absolute;
  cursor: grab;
  user-select: none;
  width: 80px;
  height: auto;
  transition: transform 0.1s ease;
}

.figure {
  -webkit-user-drag: none;
  user-select: none;
  touch-action: none;
  cursor: grab;
}

@media screen and (max-width: 500px) {
  .container-image {
    width: 100%;
  }
}


</style>
